@import 'app.scss';
.section.marginTop {
  @include from-breakpoint(lg) {
    margin-top: spacing(12);
  }
}

.navContainer {
  background-color: var(--color-bg-default);
}

.navWrapper {
  @include container-width;

  display: flex;
  flex-wrap: nowrap;
  gap: spacing(9);
  align-items: center;
  overflow-x: auto;

  @include from-breakpoint(md) {
    gap: spacing(7);
  }

  @include from-breakpoint(xl) {
    margin-right: 0;
    max-width: calc(100% - (100% - var(--max-container-width)) / 2);
  }

  @include from-breakpoint(2xl) {
    padding-left: 0;
  }
}

.active {
  position: relative;
  padding-top: spacing(2);
  padding-bottom: spacing(2);

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--color-bg-brand);
    width: 100%;
    height: 0.25rem;
    content: '';
  }
}
